@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");

body {
  font-family: "Roboto Condensed", system-ui !important;
  font-optical-sizing: auto;
  font-style: normal;
  background: linear-gradient(
    109.6deg,
    rgb(36, 45, 57) 11.2%,
    rgb(16, 37, 60) 51.2%,
    rgb(0, 0, 0) 98.6%
  );
  color: #fff !important;
  height: 100vh !important;
}
.card {
  background: transparent !important;
  box-shadow: rgba(99, 99, 110, 0.2) 0px 7px 29px 0px;
  color: #fff !important;
  border: 1px solid darkgray !important;
}
.card-style {
  border: 1px solid darkgray !important;
  border-radius: 0px !important;
}

.container {
  height: 100vh !important;
}
.Container {
  height: 100vh !important;
  align-items: center;
  overflow-y: hidden !important;
}
a {
  text-decoration: none !important;
}

.cell-wrapper {
  white-space: nowrap;
  overflow-x: auto;
  max-width: 800px;
}
.cell-wrapper-new {
  max-width: 600px !important;
}
/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .cell-wrapper {
    max-width: 150px; /* Adjust width for smaller screens */
    width: 900px;
  }
}
.cell-wrapper::-webkit-scrollbar {
  height: 4px; /* Thin scrollbar */
}

.cell-wrapper::-webkit-scrollbar-track {
  background: transparent; /* Track background */
}

.cell-wrapper::-webkit-scrollbar-thumb {
  background: #888; /* Scrollbar thumb color */
  border-radius: 10px; /* Round scrollbar thumb */
}

.cell-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker color on hover */
}

/* Custom scrollbar styles for Firefox */
.cell-wrapper {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: #888 transparent; /* Thumb and track color */
}

/* Hide default scrollbar arrows (Cross-browser solution) */
.cell-wrapper {
  -ms-overflow-style: none; /* IE and Edge */
}

.select-style {
  color: black !important;
}
.css-1nmdiq5-menu {
  background-color: white !important;
  /* height: 100px !important; */
}
.input-style {
  border-radius: 0 !important;
}

.css-1lu6d3e-MuiStack-root {
  align-items: end !important;
  color: #fff !important;
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  color: #fff !important;
}

.css-1btmbba-MuiButtonBase-root-MuiButton-root.Mui-disabled{
  color: #fff !important;
}